<template>
    <header class="page-title">
        <h1><i class="bi bi-bar-chart-fill"></i> レポート - 追客</h1>
    </header>

    <!-- 検索欄 -->
    <section class="section">
        <div class="row align-items-end">
            <div class="col-6">
                <label class="form-label">評価</label>
                <form-select
                    v-model="condition.rank"
                    :options="[{value: 1, label: '見込み'}, {value: 2, label: 'コナン'}]"
                ></form-select>
            </div>
            <div class="col-12">
                <label class="form-label">事業部</label>
                <form-select-search-multiple
                    v-model="condition.department_ids"
                    :options="options_department"
                >
                </form-select-search-multiple>
            </div>
            <div class="col-10">
                <label class="form-label">次回アプローチ日</label>
                <div class="input-group">
                    <form-input-date
                        v-model="condition.next_approaching_date_from"
                    ></form-input-date>
                    <span class="input-group-text">〜</span>
                    <form-input-date
                        v-model="condition.next_approaching_date_to"
                    ></form-input-date>
                </div>
            </div>
            <div class="col-6">
                <button @click="search()" :disabled="loading" class="btn btn-primary">表示</button>
            </div>
        </div>
    </section>

    <section class="section">
        <inline-loader v-if="loading"></inline-loader>
        <template v-else>
            <div v-if="collections.length">
                <div class="mx-1 my-3 px-3 d-flex align-items-center">
                    <p class="my-0 me-5">合計 : {{ total() }} 件</p>
                </div>
                <div class="mb-3">
                    <table class="table table-bordered">
                        <thead>
                            <tr class="table-dark">
                                <th class="text-center col-4">次回アプローチ日</th>
                                <th class="text-center col-1"></th>
                                <th class="text-center col-4">追客担当</th>
                                <th class="text-center col-1"></th>
                                <th class="text-center col-4">見込日</th>
                                <th class="text-center col-1"></th>
                                <th class="text-center col-5">会社名</th>
                                <th class="text-center col-7">追客計画</th>
                                <th class="text-center col-7">URL</th>
                                <th class="text-center col-2"></th>
                            </tr>
                        </thead>

                        <tbody>
                            <template v-for="approach_date in collections" :key="approach_date.approach_date">
                                <template v-for="user, j in approach_date.users" :key="user.user_name">
                                    <template v-for="expected_date, k in user.expected_dates" :key="expected_date.expected_date">
                                        <template v-for="company, l in expected_date.companies" :key="l">
                                            <tr>
                                                <template v-if="j === 0 && k === 0 && l === 0">
                                                    <td class="align-middle" :rowspan="approach_date.count"><div>{{ approach_date.approach_date }}</div></td>
                                                    <td class="align-middle text-center" :rowspan="approach_date.count"><div>{{ approach_date.count }}</div></td>
                                                </template>
                                                <template v-if="k === 0 && l === 0">
                                                    <td class="align-middle" :rowspan="user.count"><div>{{ user.user_name }}</div></td>
                                                    <td class="align-middle text-center" :rowspan="user.count"><div>{{ user.count }}</div></td>
                                                </template>
                                                <template v-if="l ===0">
                                                    <td class="align-middle" :rowspan="expected_date.count"><div>{{ expected_date.expected_date }}</div></td>
                                                    <td class="align-middle text-center" :rowspan="expected_date.count"><div>{{ expected_date.count }}</div></td>
                                                </template>
                                                <td class="align-middle"><div>{{ company.company_name }}</div></td>
                                                <td class="align-middle prewrap"><div>{{ company.follow_plan }}</div></td>
                                                <td class="align-middle word-break-all">
                                                    <div>{{ routeProjectEdit(company.project_id) }}</div>
                                                </td>
                                                <td class="align-middle text-center">
                                                    <router-link
                                                        :to="{name: 'ProjectEdit', params: {id: company.project_id}}"
                                                        class="btn btn-outline-primary"
                                                        target="_blank"
                                                    >
                                                        詳細
                                                    </router-link>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                </template>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
            <template v-else>
                <p class="alert alert-secondary">※追客レポートがありません</p>
            </template>
        </template>
    </section>

</template>

<script>
import InlineLoader from '@/components/tools/InlineLoader';
import FormSelect from '@/components/forms/FormSelect';
import FormInputDate from '@/components/forms/FormInputDate';
import FormSelectSearchMultiple from '@/components/forms/FormSelectSearchMultiple';
import Rank from '@/models/enums/rank';
import Department from '@/models/entities/department';

export default {
    name: 'ReportFollowUp',
    components: {
        FormSelect,
        FormInputDate,
        FormSelectSearchMultiple,
        InlineLoader,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // ローディング
            loading: false,

            Rank,

            // 検索条件
            condition: {
                rank: Rank.PROSPECT,
                department_ids: [],
                next_approaching_date_from: this.$helper.today(),
                next_approaching_date_to: null,
            },

            options_department: [],

            collections: [],
        }
    },
    mounted() {
        // next_approaching_date_toに初期値セット 当日から13日後
        const today = new Date();
        this.condition.next_approaching_date_to = this.$helper.ymdForInputDate(new Date(today.setDate(today.getDate() + 13)));

        // ログインユーザ取得、事業部取得、検索
        this.init();
    },
    methods: {
        async init() {
            await this.fetchDepartments();
            this.search();
        },
        fetchDepartments() {
            return new Promise(resolve => {
                this.loading = true;
                // 事業部マスタ取得
                this.$http.get('/master/department')
                .then(response => {
                    for (let row of response.data) {
                        const department = new Department(row);
                        this.options_department.push({value: department.department_id, label: department.department_name});
                    }

                    //事業部未登録の場合
                    if (this.options_department.length === 0) {
                        this.showErrorMessage('事業部が登録されていません。先に事業部を登録してください');
                    }
                    if (this.$store.state.auth.department_id) {
                        // 自身の事業部を設定する
                        this.condition.department_ids.push(this.$store.state.auth.department_id);
                    } else {
                        // 所属する事業部がなければ1つ目の事業部を設定
                        this.condition.department_ids.push(this.options_department[0].value);
                    }
                })
                .finally(() => {
                    this.loading = false;
                    resolve(true);
                })
            });
        },
        search() {
            // 次回アプローチ日期間チェック
            if (this.invalidNextApproachingDate()) {
                return;
            }

            this.loading = true;

            this.collections.splice(0);

            this.$http.get('/report/follow-up', {
                params: this.condition,
            })
            .then(response => {
                for (let row of response.data) {
                    this.collections.push(row);
                }
            })
            .finally(() => {
                this.loading = false;
            });
        },
        invalidNextApproachingDate() {
            if (
                this.$helper.isBlank(this.condition.next_approaching_date_from)
                || this.$helper.isBlank(this.condition.next_approaching_date_to)
            ) {
                this.showErrorMessage('次回アプローチ日を設定してください');
                return true;
            }

            const from = new Date(this.condition.next_approaching_date_from);
            const to = new Date(this.condition.next_approaching_date_to);

            if (from > to) {
                this.showErrorMessage('次回アプローチ日の始まり日は終わり日より早い日にしてください');
                return true;
            }

            // fromの30日後よりtoが大きい場合
            const from_copy = new Date(from.getTime());
            const max_from = new Date(from_copy.setDate(from_copy.getDate() + 30));
            if (max_from < to) {
                this.showErrorMessage('次回アプローチ日の最大指定期間は30日間までです');
                return true;
            }

            return false;
        },
        total() {
            let total = 0;
            for (let collection of this.collections) {
                total += collection.count;
            }

            return total;
        },
        routeProjectEdit(project_id) {
            return location.protocol + "//"
                + location.host
                + this.$router.resolve({ name: 'ProjectEdit', params: {id: project_id} }).href;
        }
    }
}
</script>

<style scoped>

</style>
